<adamp-global-loader></adamp-global-loader>
<app-toasts></app-toasts>

<div class="flex flex-row flex-nowrap">
  <div
    class="vh-100 side-nav-container"
    *ngIf="auth.loggedIn"
  >
    <app-sidenav> </app-sidenav>
  </div>

  <div
    class="a-page-card"
    style="flex-grow: 1; background-color: transparent"
  >
    <app-section-header></app-section-header>

    <main>
      <router-outlet (activate)="changingPage()"></router-outlet>
    </main>
  </div>
</div>

<p-dialog
  [modal]="true"
  [style]="{ width: '600px' }"
  [(visible)]="appReloadRequired"
  [draggable]="false"
  [resizable]="false"
  class="mono-ghost-background"
>
  <ng-template pTemplate="header">
    <h4 class="pl-6">{{ 'appReloadAfterUpdateDialog.title' | translate }}</h4>
  </ng-template>

  <div class="px-6 mb-5">
    {{ 'appReloadAfterUpdateDialog.message' | translate }}
  </div>
  <div class="px-6 flex justify-content-end p-align-middle">
    <p-button
      type="button"
      icon="pi pi-times"
      [label]="'common.cancel' | translate"
      class="regular-text uppercase mr-3"
      (click)="appReloadRequired = false"
    ></p-button>
    <p-button
      type="button"
      icon="pi pi-refresh"
      class="small-yellow no-wrap"
      (onClick)="reloadApp()"
      [label]="'appReloadAfterUpdateDialog.buttonText' | translate"
    ></p-button>
  </div>
</p-dialog>
