<section class="error-page flex justify-content-center">
  <div class="flex flex-column gap-6">
    <h1 class="error-header">{{ 'pageNotFound.title' | translate }}</h1>
    <b>{{ 'pageNotFound.description' | translate }} </b>
    <a
      class="medium-yellow block w-fit"
      [routerLink]="['/dashboard']"
      >{{ 'pageNotFound.button' | translate }}</a
    >
  </div>
  <div class="mt-6">
    <img
      src="../../../assets/images/error-page.svg"
      alt=""
    />
  </div>
  <div class="roadline"></div>
</section>
