import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found.component';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [ButtonModule, RouterModule, TranslateModule]
})
export class PageNotFoundModule {}
