export const environment = {
  production: true,
  landingPageUrl: 'https://prilo.com',
  amplify: {
    Auth: {
      identityPoolId: '',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_BRm7ezw10',
      userPoolWebClientId: '3tkldqi1jh2df67l70n9hldiut'
    }
  },
  kms: {
    accessKeyId: 'AKIAX3TYZW53J6MS4PHY',
    secretAccessKey: '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',
    keyId: 'arn:aws:kms:eu-west-1:540345481078:key/f0dab157-2cbc-4f85-bb45-8178285155d1'
  },
  config: {
    apiUrl: 'https://ygva6t54rl.execute-api.eu-west-1.amazonaws.com/api',
    checkEmailEndpoint: '/users/check-email',
    getMakesEndpoint: '/vehicle/makes',
    getModelsEndpoint: '/vehicle/models',
    getBodyTypesEndpoint: '/vehicle/types',
    getMakesModelsAndBodyTypes: '/vehicle/models-and-types',
    getOrdersEndpoint: '/orders',
    getOrdersEditEndpoint: '/orders/orderId/edit-details',
    draftEndpoint: '/orders/draft',
    getPaymentsEndpoint: '/payments',
    getPaymentsInvoiceEndpoint: '/payments/invoiceId/invoice',
    getPaymentsInvoiceEndpointOpen: '/payments/invoiceId/invoice/open',
    getPaymentsCorrectedInvoiceEndpoint: '/payments/invoiceId/invoice/corrective',
    getPaymentsCorrectedInvoiceEndpointOpen: '/payments/invoiceId/invoice/corrective/open',
    isPaymentPaidEndpoint: '/payments/order/orderId/is-paid',
    searchListingsEndpoint: '/listings',
    calculateCommissionEndpoint: '/commission/calculate',
    getTruckMakesEndpoint: '/vehicle/truck/makes',
    getTruckSuperstructureModelsEndpoint: '/vehicle/truck/superstructures',
    getTruckTrailerModelsEndpoint: '/vehicle/truck/trailerModels',
    getTruckEmissionsEndpoint: '/vehicle/truck/emissions',
    trucksEndpoint: '/trucks',
    getTruckDeleteEndpoint: '/truckId/remove',
    searchOffersEndpoint: '/offers',
    offersShorts: '/offers/short-lists',
    orderSearchEndpoint: '/orders',
    ordersShorts: '/orders/short-lists',
    phonesEndpoint: '/phones',
    removePhoneNumberEndpoint: '/phoneId/remove',
    driversEndpoint: '/drivers',
    removeDriverEndpoint: '/driverId/remove',
    convertFileToCarsEndpoint: '/cars/import',
    deliveryDetailsEndpoint: '/shipment/delivery-details/shipmentId',
    getShipmentEndpoint: '/shipment/orderId',
    updateOrDeleteDeliveryDetailsEndpoint: '/deliveryDetailsId',
    assignCarsEndpoint: '/deliveryDetailsId/attach-cars',
    notificationsEndpoint: '/notifications',
    shipmentDataEndpoint: '/shipment/data/orderId',
    shipmentDataRolesEndpoint: '/shipment/data/orderId/roles',
    updateEtaEndpoint: '/shipmentItemId',
    updateVinEndpoint: '/deliveryDetailsId/car/carId',
    shipperAcceptVinsEndpoint: '/deliveryDetailsId/shipper-accept',
    carrierAcceptVinsEndpoint: '/deliveryDetailsId/carrier-accept',
    companyInfoEndpoint: '/orderId/companyInfo',
    companyProfilesEndpoint: '/company-profiles/details',
    commentsEndpoint: '/orders/comments',
    getDocumentEndpoint: '/documents/documentId',
    deleteDocumentEndpoint: '/documents/documentId',
    getDocumentsListEndpoint: '/documents/order/orderId',
    uploadDocumentEndpoint: '/documents/order',
    uploadDocumentPrepareEndpoint: '/documents/order/prepare',
    getTrucksForOrder: '/shipment/orderId/trucks',
    getUsersEndpoint: '/users',
    staticMapEndpoint: '/map/folder/item',
    carrierDocumentsEndpoint: '/company-profiles/files',
    getInsuranceDataEndpoint: '/company-profiles/files/insurance',
    getLicenceDataEndpoint: '/company-profiles/files/licence',
    getTrackingDataEndpoint: '/tracking',
    companyFavouritesEndpoint: '/companies/favourites',
    getCompanyDetailsFromViesEndpoint: '/vies/company',
    chatLoginEndpoint: '/chat/login',
    carrierTrackingTrucksEndpoint: '/shipment/delivery-details/transporting',
    carrierTrackingTruckEndpoint: '/shipment/delivery-details/transporting/truckId',
    getInvitationCompanyDataEndpoint: '/invitation/invitationId',
    registerUserFromInvitationEndpoint: '/invitation/registration',
    updateOrderPriceAndVisibilityEndpoint: '/orders/orderId/update',
    updateUserLanguage: '/users/language',
    getPhoneNumberDataEndpoint: '/phones/phoneId',
    terminateDealEndpoint: '/orders/orderId/terminate',
    getTerminationDataEndpoint: '/orders/orderId/termination/details',
    acceptTerminationEndpoint: '/orders/orderId/termination/accept',
    getOrderHistoryEndpoint: '/orders/history/orderId',
    editCompanyRoleEndpoint: '/companies',
    companyDeleteEndpoint: '/companies/delete-request',
    getCompaniesShipperEndpoint: '/companies/shipper-search',
    getOffersCompaniesShipperEndpoint: '/offers/shippers/known/search',
    getOrdersCompaniesCarrierEndpoint: '/orders/carriers/known/search',
    addOrderToFavouritesEndpoint: '/orders/favourites/favourites',
    deleteOrderFromFavouritesEndpoint: '/orders/favourites/favourites/orderId',
    getOrderFavouritesEndpoint: '/orders/favourites/favourites/orderId',
    getOrderListFavouritesEndpoint: '/orders/favourites/favourites/list',
    getOrderStatisticsEndpoint: '/statistics/order',
    getOrderFollowersCountEndpoint: '/orders/favourites/favourites/orderId/users-count',
    getRouteListFavouritesEndpoint: '/favourite-routes',
    addRouteToFavouritesEndpoint: '/favourite-routes',
    deleteRouteFromFavouritesEndpoint: '/favourite-routes/routeId',
    toggleRouteNotificationsEnabledEndpoint: '/favourite-routes/notifications',
    getFavouritesRoutesCountEndpoint: '/listings/matching-routes/counts'
  },
  map: {
    apiKey: 'bVDppVuUxMYZkAbCJORvN8IsykTEA0E4',
    fuzzySearchLimit: 10,
    languageCode: 'en-GB'
  },
  hereMap: {
    apiKey: '3jOCleumPy9slBaZWXvvH4QsMn0XLSEHlLYE_SWkk-g',
    apiVersion: 8,
    languageCode: 'en-GB'
  },
  streamChat: {
    apiKey: 'j3n8pd3free5',
    inactivityChannelCid: 'void:void',
    channelsPageSize: 30,
    // how many pages of channels load at the start
    initialChannelsPagesNumber: 3
  },
  appReload: {
    watchInterval: 5 * 60 * 1000
  },
  googleTagManager: {
    id: 'GTM-56N9B3S'
  }
};
