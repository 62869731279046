import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleSelectionComponent } from './views/role-selection/role-selection.component';
import { UserDataComponent } from './views/user-data/user-data.component';
import { CompanyDataComponent } from './views/company-data/company-data.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { HelpCenterComponent } from './views/help-center/help-center.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { AuthGuard } from './guards/auth.guard';
import { TermsComponent } from './views/terms/terms.component';
import { RegistrationStepGuard } from './guards/registration-step.guard';
import { ConfirmationGuard } from './guards/confirmation.guard';
import { NegateAuthGuard } from './guards/negate-auth.guard';
import { ListingsComponent } from './listings/listings.component';
import { RegistrationVerificatedConfirmationComponent } from './views/registration-verificated-confirmation/registration-verificated-confirmation.component';
import { StaticMapComponent } from './views/static-map/static-map.component';
import { InvitationRegistrationComponent } from './views/invitation-registration/invitation-registration.component';
import { UserDataInvitationComponent } from './views/invitation-registration/user-data-invitation/user-data-invitation.component';
import { TermsInvitationComponent } from './views/invitation-registration/terms-invitation/terms-invitation.component';
import { ConfirmationInvitationComponent } from './views/invitation-registration/confirmation-invitation/confirmation-invitation.component';
import { InvitationRegistrationStepGuard } from './guards/invitation-registration-step.guard';
import { RoleGuard } from './guards/role.guard';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  { path: 'sign-in', component: SignInComponent, canActivate: [NegateAuthGuard] },
  { path: 'role-selection', component: RoleSelectionComponent, canActivate: [NegateAuthGuard] },
  {
    path: 'company-data',
    component: CompanyDataComponent,
    canActivate: [NegateAuthGuard, RegistrationStepGuard],
    canDeactivate: [RegistrationStepGuard],
    data: { step: 1 }
  },
  {
    path: 'user-data',
    component: UserDataComponent,
    canActivate: [NegateAuthGuard, RegistrationStepGuard],
    canDeactivate: [RegistrationStepGuard],
    data: { step: 2 }
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: [NegateAuthGuard, RegistrationStepGuard],
    canDeactivate: [RegistrationStepGuard],
    data: { step: 3 }
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [NegateAuthGuard, RegistrationStepGuard, ConfirmationGuard],
    canDeactivate: [ConfirmationGuard],
    data: { step: 4 }
  },
  { path: 'help-center', component: HelpCenterComponent, canActivate: [AuthGuard] },
  {
    path: 'shipment-order',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/shipment-order/shipment-order.module').then((module) => module.ShipmentOrderModule),
    data: { roles: ['SHIPPER'] }
  },
  {
    path: 'shipment-order/duplicate/:orderId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/shipment-order/shipment-order.module').then((module) => module.ShipmentOrderModule),
    data: { roles: ['SHIPPER'] }
  },
  {
    path: 'order-draft-edit/:orderDraftId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/order-draft-edit/order-draft-edit.module').then((module) => module.OrderDraftEditModule)
  },
  {
    path: 'carrier-offer/:orderId',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./views/carrier-offer/carrier-offer.module').then((module) => module.CarrierOfferModule)
  },
  {
    path: 'order-offers/:orderId',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/order-offers/order-offers.module').then((m) => m.OrderOffersModule)
  },
  {
    path: 'order-edit/:orderId',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/order-edit/order-edit.module').then((m) => m.OrderEditModule)
  },
  { path: 'search', component: ListingsComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  { path: 'verification-confirmation', component: RegistrationVerificatedConfirmationComponent },
  {
    path: 'payments-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/payments-list/payments-list.module').then((module) => module.PaymentsListModule)
  },
  {
    path: 'offer-search',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/offer-search/offer-search.module').then((module) => module.OfferSearchModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'offer-details',
    canActivate: [AuthGuard, RoleGuard],
    data: { requiredRoles: ['CARRIER'] },
    loadChildren: () =>
      import('./views/shipment-details/views/offer-details/offer-details.module').then(
        (module) => module.OfferDetailsModule
      )
  },
  {
    path: 'order-details',
    canActivate: [AuthGuard, RoleGuard],
    data: { requiredRoles: ['SHIPPER'] },
    loadChildren: () =>
      import('./views/shipment-details/views/order-details/order-details.module').then(
        (module) => module.OrderDetailsModule
      )
  },
  {
    path: 'order-search',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/orders-search/orders-search.module').then((module) => module.OrdersSearchModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/shared/notification/notification-list/notification-list.module').then(
        (module) => module.NotificationListModule
      )
  },
  {
    path: 'my-account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/my-account/my-account.module').then((module) => module.MyAccountModule)
  },
  { path: 'static-map', component: StaticMapComponent },
  {
    path: 'favourites',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/favourites/favourites.module').then((module) => module.FavouritesModule)
  },
  {
    path: 'order-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/order-history/order-history.module').then((module) => module.OrderHistoryModule)
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./views/chat/chat.module').then((module) => module.ChatModule)
  },
  {
    path: 'carrier-tracking',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/carrier-tracking/carrier-tracking.module').then((module) => module.CarrierTrackingModule)
  },
  { path: 'invitation-registration', component: InvitationRegistrationComponent, canActivate: [NegateAuthGuard] },
  {
    path: 'user-data-invitation',
    component: UserDataInvitationComponent,
    canActivate: [NegateAuthGuard, InvitationRegistrationStepGuard],
    canDeactivate: [InvitationRegistrationStepGuard],
    data: { step: 1 }
  },
  {
    path: 'terms-invitation',
    component: TermsInvitationComponent,
    canActivate: [NegateAuthGuard, InvitationRegistrationStepGuard],
    canDeactivate: [InvitationRegistrationStepGuard],
    data: { step: 2 }
  },
  {
    path: 'confirmation-invitation',
    component: ConfirmationInvitationComponent,
    canActivate: [NegateAuthGuard, InvitationRegistrationStepGuard],
    canDeactivate: [ConfirmationGuard],
    data: { step: 3 }
  },
  {
    path: 'password-recovery',
    canActivate: [NegateAuthGuard],
    loadChildren: () =>
      import('./views/password-recovery/password-recovery.module').then((module) => module.PasswordRecoveryModule)
  },
  {
    path: 'new-password',
    canActivate: [NegateAuthGuard],
    loadChildren: () =>
      import('./views/password-recovery/new-password/new-password.module').then((module) => module.NewPasswordModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
