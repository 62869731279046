/* eslint-disable @typescript-eslint/ban-types */
import Dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
Dayjs.extend(updateLocale);

// import DayJS locale as needed
import 'dayjs/locale/pl';
import 'dayjs/locale/lt';

import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { Observable } from 'rxjs';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpInterceptorImpl } from './interceptors/http-interceptor-impl.service';
import { JwtInterceptorService } from './interceptors/jwt-interceptor.service';
import { ListingsModule } from './listings/listings.module';
import { CountryCodeModule } from './pipes/country-code/country-code.module';
import { GlobalSettingsService } from './services/global-settings.service';
import { RegistrationService } from './services/registration.service';
import { LanguageService } from './services/language.service';
import { SharedModule } from './shared/shared.module';
import { CompanyDataComponent } from './views/company-data/company-data.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { HelpCenterComponent } from './views/help-center/help-center.component';
import { OfferInfoModule } from './views/offer-info/offer-info.module';
import { OrderDetailModule } from './views/order-detail/order-detail.module';
import { RegistrationVerificatedConfirmationComponent } from './views/registration-verificated-confirmation/registration-verificated-confirmation.component';
import { RegistrationComponent } from './views/registration/registration.component';
import { RoleSelectionComponent } from './views/role-selection/role-selection.component';
import { CheckboxButtonComponent } from './views/shared/checkbox-button/checkbox-button.component';
import { SectionHeaderModule } from './views/shared/section-header/section-header.module';
import { SidenavModule } from './views/sidenav/sidenav.module';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { TermsComponent } from './views/terms/terms.component';
import { UserDataComponent } from './views/user-data/user-data.component';
import { CarsTableModule } from './views/util/cars-table/cars-table.module';
import { CommentsModule } from './views/util/comments/comments.module';
import { StaticMapComponent } from './views/static-map/static-map.component';
import { UploadCompanyDocumentComponent } from './views/company-data/upload-company-document/upload-company-document.component';
import { CookieBarModule } from './views/cookie-bar/cookie-bar.module';
import { CookieService } from 'ngx-cookie-service';
import { UserDataFormComponent } from './views/user-data/user-data-form/user-data-form.component';
import { UserDataInvitationComponent } from './views/invitation-registration/user-data-invitation/user-data-invitation.component';
import { InvitationRegistrationComponent } from './views/invitation-registration/invitation-registration.component';
import { RegistrationStepsComponent } from './views/invitation-registration/registration-steps/registration-steps.component';
import { TermsContentComponent } from './views/terms/terms-content/terms-content.component';
import { ConfirmationContentComponent } from './views/confirmation/confirmation-content/confirmation-content.component';
import { TermsInvitationComponent } from './views/invitation-registration/terms-invitation/terms-invitation.component';
import { ConfirmationInvitationComponent } from './views/invitation-registration/confirmation-invitation/confirmation-invitation.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { HttpErrorInterceptorService } from './interceptors/http-error-interceptor.service';
import { AlertDialogComponent } from './views/shared/alert-dialog/alert-dialog.component';
import { HistoryScrollPositionService } from './services/history-scroll-position.service';
import { GlobalLoaderModule } from './views/shared/global-loader/global-loader.module';
import { LoaderModule } from './views/shared/loader/loader.module';
import { TeleportModule } from '@ngneat/overview';
import { ToastModule } from 'primeng/toast';
import { PageNotFoundModule } from './views/page-not-found/page-not-found.module';

registerLocaleData(localeDE);

class TranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json'
  ) {}

  public getTranslation(lang: string): Observable<Object> {
    const timestamp = new Date().getTime();
    return this.http.get(`${this.prefix}${lang}${this.suffix}?${timestamp}`);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    RoleSelectionComponent,
    UserDataComponent,
    CompanyDataComponent,
    RegistrationComponent,
    ConfirmationComponent,
    HelpCenterComponent,
    TermsComponent,
    CheckboxButtonComponent,
    RegistrationVerificatedConfirmationComponent,
    StaticMapComponent,
    UploadCompanyDocumentComponent,
    StaticMapComponent,
    UserDataFormComponent,
    UserDataInvitationComponent,
    InvitationRegistrationComponent,
    RegistrationStepsComponent,
    TermsContentComponent,
    TermsInvitationComponent,
    ConfirmationContentComponent,
    ConfirmationInvitationComponent,
    AlertDialogComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    MenubarModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    TooltipModule,
    CheckboxModule,
    StepsModule,
    HttpClientModule,
    RippleModule,
    ProgressSpinnerModule,
    DividerModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    InputNumberModule,
    RadioButtonModule,
    PanelModule,
    NgbModule,
    FileUploadModule,
    RatingModule,
    TableModule,
    AutoCompleteModule,
    RxReactiveFormsModule,
    DialogModule,
    SplitButtonModule,
    ListingsModule,
    SharedModule,
    OrderDetailModule,
    CommentsModule,
    CarsTableModule,
    OfferInfoModule,
    CountryCodeModule,
    SectionHeaderModule,
    SidenavModule,
    CookieBarModule.forRoot(),
    DynamicDialogModule,
    GlobalLoaderModule,
    LoaderModule,
    TeleportModule,
    ToastModule,
    PageNotFoundModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorImpl, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
    { provide: ErrorHandler, useExisting: GlobalErrorHandler },
    RegistrationService,
    DatePipe,
    CookieService,
    LanguageService,
    GlobalSettingsService,
    CookieService,
    DialogService,
    RegistrationService,
    HistoryScrollPositionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
